<template>
	<div>
		<AppointmentComponent></AppointmentComponent>
	</div>
</template>

<script type="text/javascript">


import AppointmentComponent from '@/components/AppointmentComponent_v2'

export default {
	name:'Appointment',
	components: {
		AppointmentComponent,
	},
	data() {
		return {
		}
	},
	computed: {
	},
	mounted () {
	},
	methods: {
	},
}
</script>
