<template>
	<div>
		<v-text-field v-model="phone" placeholder='+7 (999) 999-99-99' outlined @keydown="checkPhone" @keyup='makeMask' type="phone"></v-text-field>
	</div>
</template>

<script type="text/javascript">
export default {
	name:'Phone',
  props: ['value',],
  //emits: ['update:value', 'updateValue', ],
	data() {
		return {
			client:'ccccc',
			county: ['ru',],
			maxLen: 4,
			//value_tmp:'',
			//phone_val: '+7',
			phone_tmp:'',
		}
	},
	computed:{
		phone:{
			get(){ return this.value },
			set(value){
				//this.$emit('update:value', value)
				this.$emit('input', value)
			}
		},
	},
	methods:{

		checkPhone(value) {
			//console.log('checkPhone:', value)
			var allow_keys = ['ArrowLeft', 'Backspace', 'Tab', 'Enter']
			if (allow_keys.indexOf(value.key)){	return }
			//if (value.key == 'Backspace' || value.key == 'Tab' || value.key == 'Enter') { return }
			if (this.phone.length > 16){
				//console.log("Max length")
				value.preventDefault()
			}
			if (!value.key.match('[0-9-()+]')) {
				value.preventDefault()
			}
			//console.log(pattern.test(value))
		},
		makeMask(event) {
			//console.log("MakeMask:(", value.ctrlKey, ")")
			//console.log("MakeMask:(", event, ")")
			var phone_tmp = this.phone
			//console.log('phone_tmp:', phone_tmp)
			//if (!value.key.match('[0-9-()+]')) {
			//}
			// Обрабатываем удаление пробелов. Если при нажатии кнопки удаления стоит проблел, удаляем два символа
			if (event.key == 'Backspace'){
				var l = phone_tmp.length
				//console.log("УДАЛЯЕМ", phone_tmp+":"+phone_tmp[l-1]+":"+l)
				if (l==8) {
					phone_tmp = phone_tmp.substr(0,6)
				}
			}
			//console.log('phone_tmp2:', phone_tmp)
			if (phone_tmp){
				// Проверяем корректность номера. Т.е. убираем все, кроме + и цифр
				// 8(963)782-5491
				var newPhone = phone_tmp.replace(/[^+\d]/g, '').replace(' ','')
				//console.log("Новый номер:", newPhone)
				// Если первый символ 8 или 7 (без +) - меняем его на +7
				if (newPhone.startsWith('8') || newPhone.startsWith('7')){
					newPhone = "+7" + newPhone.substr(1, newPhone.length)
				}
				if (!newPhone.startsWith('+7')){
					newPhone = "+7" + newPhone
				}
				// Удаляем + если есть в самом номере
				newPhone = "+"+newPhone.substr(1, newPhone.length).replace('+','')
				// Преобразуем номер в красивый формат
				var phonePref = newPhone.substr(2,3)
				var phoneOct1 = newPhone.substr(5,3)
				var phoneOct2 = newPhone.substr(8,2)
				var phoneOct3 = newPhone.substr(10,2)
				//console.log('phonePref: (', phonePref, ') ', phoneOct1, "-", phoneOct2, "-", phoneOct3)
				//console.log("Новый номер:", newPhone)
				// Формурем красивый номер в выводе
				phone_tmp = '+7 ('+phonePref
				if (phonePref.length == 3) { phone_tmp += ') ' }
				if (phoneOct1){ phone_tmp += phoneOct1 }
				if (phoneOct2){ phone_tmp += "-"+phoneOct2 }
				if (phoneOct3){ phone_tmp += "-"+phoneOct3 }
				//console.log('phonePref: (', phonePref, ') ', phoneOct1, "-", phoneOct2, "-", phoneOct3)
				//console.log("Новый номер:", phone_tmp)
				// '+ phoneOct1+ "-" + phoneOct2 + "-" + phoneOct3
				/*
				if (newPhone.startsWith('+7') && newPhone.length == 12) {
					console.log("НОМЕР КОРРЕКТНЫЙ")
					//this.record.phone = newPhone
				}
				*/
				this.phone = phone_tmp
			}
		}
	},
}
</script>