<template>
	<v-card elevation="2"  outlined>
		<v-alert type="success" v-if="success"><div v-html="mess"></div></v-alert>
		<v-card-title><div class=" mb-4 text-h5 text-uppercase">Запись к врачу</div></v-card-title>
		<v-card-text class="text--primary">
			<v-list>
				<v-list-item>
					<v-list-item-content><v-autocomplete :items="doctor_items" v-model="doctor" label="Выберите специалиста" outlined ></v-autocomplete></v-list-item-content>
				</v-list-item>
				
				<v-list-item two-line>
					<v-list-item-content>
						<v-list-item-title>Желаемая дата приема {{ date }}</v-list-item-title>
						<v-list-item-subtitle><v-calendar v-model="date" color="primary"></v-calendar></v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<v-list-item two-line>
				<v-list-item-content>
					<v-list-item-title>   <v-text-field label="Ваше имя" v-model="client" outlined ></v-text-field></v-list-item-title>
					<!--
					<v-list-item-subtitle class="pt-2">
						<vue-tel-input-vuetify v-model="phone" :onlyCountries="county" :maxlength='maxLen' @keydown="checkPhone" outlined required :rules="[v=>!!v || 'Введите телефон']"></vue-tel-input-vuetify>
					</v-list-item-subtitle>
					-->
					<v-list-item-subtitle>
						<!--<v-text-field v-model="phone" placeholder='+7 (999) 999-99-99' outlined @keydown="checkPhone" @keyup='makeMask' type="phone"></v-text-field>-->
						<Phone v-model="phone" />
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>

		</v-card-text>

		<v-card-actions>
			<v-btn color="red" text>Отменить</v-btn>

			<v-btn color="green" text @click="sendRequestRecord">Записаться</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script type="text/javascript">
//import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue"
import Phone from "@/components/Phone.vue"

export default {
	components:{
		//VueTelInputVuetify,
		Phone
	},
	data(){
		return {
			date_tmp: null,
			success: false,
			client:'',
			phone:'',
			doctor:'',
			doctor_items: [
				'Терапевт',
				'Хирург',
				'Кардиолог',
				'Эндокринолог',
				'Невролог',
				'Нейрохирург',
				'Ортопед',
				'Герпитолог',
				'Онколог',
				'Ратолог',
				'Ортитолог',
				],
			noClear: [v=>!!v || 'Поле не должно быть пустым']
		}
	},
	computed:{
		date:{
			get() { 
				//return this.date_tmp.toLocaleDateString() ? this.date_tmp : ''
				if (this.date_tmp){
					return this.date_tmp.toLocaleDateString()
				} else {
					return new Date().toLocaleDateString()
				}
			},
			set(val){
				// При получении даты, парсим ее и получаем new Date
				const [year, month, day] = val.split('-')
				const date = new Date(year, month, day)
				this.date_tmp = date
				//this.date_tmp = day+"/"+month+"/"+year
			}
		},
		mess:{
			get() {
				var mess = 'Спасибо, '+ this.client +'!<br>'
				mess += this.doctor + ' принял вашу заявку на '+ this.date 
				mess +=' Ожидайте звонка оператора. '
				return mess
			}
		}
	},
	methods:{
		sendRequestRecord(){
			console.log("sendRequestRecord:", this.doctor, this.date, this.phone, this.client)
			var data = {
				action:'PUT', 
				date:this.date, 
				client_phone:this.phone,
				reason_for_petition:'Отправлен запрос '+ this.client + ' на запись к врачу:'+this.doctor,
			}
			
			if (this.doctor && this.date && this.phone && this.client){
				console.log("Отправляем запрос на запись")
				this.$store.dispatch('sendClientAppointmentsApplication', data)
				this.success = true
				// Очищаем ранее заполненные данные
				this.doctor = ''
				this.date_tmp = new Date()
				this.phone = ''
				this.client = ''
			} else {
				alert("Заполните пожалуйста все поля")
			}
		}
	},
}

</script>